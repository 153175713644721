



















































































































































import { Vue, Component } from "vue-property-decorator";

import { Input, Button, Table, TableColumn } from "element-ui";
import pagination from "@/components/UIComponents/Pagination.vue";

import {
  MetaModule as metaStore,
  GPSServiceProviderModule as gpsServiceStore,
} from "@/store/modules";
import helpers from "../../utils/helpers";
import { AdminRouter } from "@/utils/routePathContsant";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
    pagination,
  },
})
export default class DutyOfficeClass extends Vue {
  gpsServiceList: any[] = [];
  gpsModelList: any[] = [];
  vehicleOwnerList: any[] = [];
  filter: {
    page: number;
    name?: number;
    model?: number;
  } = {
    page: 1,
  };

  get AdminRouter() {
    return AdminRouter;
  }

  async created() {
    const user_id = helpers.getUserId();
    if (user_id){
      gpsServiceStore.loadGpsDevice(user_id).then(() => this.gpsModelList = gpsServiceStore.gpsDeviceList)
    }
    gpsServiceStore.loadAllAssociatedCompanyOfGpsProvider().then(() => this.vehicleOwnerList = gpsServiceStore.vehicleOwnerOfProvider);
    await gpsServiceStore.loadAllMainGpsServiceProvider(this.filter);
    this.gpsServiceList = gpsServiceStore.gpsMainList;
  }

  momentDate(date: any) {
    return [helpers.toMomentFormat(date), helpers.toMomentTime(date)];
  }

  async filterGpsServiceProvider() {
    await gpsServiceStore.loadAllMainGpsServiceProvider(this.filter);
    this.gpsServiceList = gpsServiceStore.gpsMainList;

  }
  clearFilter() {
    this.filter = {
      page: 1
    };
    this.gpsServiceList = gpsServiceStore.gpsServiceProviderList;
  }

  async handlePageChange(val: number) {
    this.filter.page = val;
    await gpsServiceStore.loadAllMainGpsServiceProvider(this.filter);
    this.gpsServiceList = gpsServiceStore.gpsMainList;
  }
}
